type NutsCodeInfo = {
  name: string;
  code: string;
};

type NutsCodeDistrict = {
  districts: NutsCodeInfo[];
};

type NutsCodeSubState = NutsCodeInfo & NutsCodeDistrict;

type NutsCode = NutsCodeInfo & {
  substates: NutsCodeSubState[];
};

/**
 * Imports the list of nutsCodes dynamically (i.e., when the function is called)
 *
 * @returns A promise that resolves to an array of nutsCodes objects.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const nutsCodes = async (): Promise<NutsCode[]> =>
  await import(
    '@moxi.gmbh/moxi-typescriptmodels/refdata/nuts-districts.json'
  ).then(nutsCodes => nutsCodes.default as NutsCode[]);
