export type HealthInsurance = {
  name: string;
  english: string;
  acronym: string;
  ikNumber: number | string;
  hasEhic: boolean;
  phone: string;
  email: string;
  fax?: string;
};

/**
 * Imports the list of health insurances dynamically (i.e., when the function is called)
 *
 * @returns A promise that resolves to an array of HealthInsurance objects.
 */
export const healthInsurances = async (): Promise<HealthInsurance[]> =>
  await import(
    '@moxi.gmbh/moxi-typescriptmodels/refdata/public-german-healthinsurances-2024Mar.json'
  ).then(insurances => insurances.default as HealthInsurance[]);
