import { Payload } from '@shared/models';
import { InjectorProvider } from '@shared/providers';
import { Observable } from 'rxjs';
import { CommandGatewayService } from './command-gateway.service';
import { QueryGatewayService } from './query-gateway.service';

/**
 * Sends a query to the server and returns an Observable of the response.
 *
 * @template T - The type of the response data.
 * @param {string} type - The type of the query.
 * @param {Payload} payload - The payload of the query.
 * @param {object} [options] - Additional options for the query.
 * @returns {Observable<T>} - An Observable of the response data.
 */
export const sendQuery = <T>(
  type: string,
  payload: Payload,
  options?: object
): Observable<T> => {
  const queryService = InjectorProvider.injector.get(QueryGatewayService);
  return queryService.send(type, payload, options);
};

/**
 * Sends a command to the command gateway service.
 *
 * @template T - The type of the response.
 * @param {string} type - The type of the command.
 * @param {Payload} payload - The payload of the command.
 * @param {object} [options] - Additional options for the command.
 * @returns {Observable<T>} - An observable that emits the response of the command.
 */
export const sendCommand = <T>(
  type: string,
  payload: Payload,
  options?: object
): Observable<T> => {
  const commandService = InjectorProvider.injector.get(CommandGatewayService);
  return commandService.send(type, payload, options);
};
