import { BreakpointObserver } from '@angular/cdk/layout';
import { computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  Account,
  AccountPermission,
  AccountRole,
  Team,
  TeamInfo,
  TeamRole,
  UserProfile
} from '@moxi.gmbh/moxi-typescriptmodels';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState
} from '@ngrx/signals';
import { LocalStorageService } from '@shared/services';
import {
  getAccountPermission,
  getAccountRoles,
  getNameInitials,
  hasAnyAccountRole,
  hasAnyTeamRole
} from '@shared/utils';
import { map } from 'rxjs';

export type AppState = {
  userProfile: UserProfile;
  selectedAccount: Account;
  impersonator: string;
  pageTitle: string;
  pageSubTitle: string;
  showHistoryBackButton: boolean;
};

const initialState: AppState = {
  userProfile: undefined,
  selectedAccount: undefined,
  impersonator: undefined,
  pageTitle: undefined,
  pageSubTitle: undefined,
  showHistoryBackButton: false
};

export const AppStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withMethods((store, localStorageService = inject(LocalStorageService)) => {
    const setUserProfile = (userProfile: UserProfile): void => {
      if (!userProfile) {
        patchState(store, {
          userProfile: undefined,
          selectedAccount: undefined
        });

        localStorageService.removeKeys([
          'Authorization',
          'SessionDeadline',
          'impersonator'
        ]);
        return;
      }

      patchState(store, {
        userProfile: {
          ...store.userProfile(),
          ...userProfile
        },
        impersonator: localStorageService.get<string>('impersonator')
      });
    };

    const setAccount = (account: Account): void => {
      patchState(store, { selectedAccount: account });
    };

    const getUniqueAccountId = (): string => {
      return store.userProfile()?.accountPermissions[0]?.accountId;
    };

    const getUserAccountPermission = (
      user: UserProfile,
      accountId: string
    ): AccountPermission => {
      return user?.accountPermissions.find(
        permissions => permissions.accountId === accountId
      );
    };

    const getAllAccountTeams = (): Team[] => {
      return store.selectedAccount()?.teams;
    };

    const getTeamInfo = (teamId: string): TeamInfo => {
      return store.selectedAccount()?.teams.find(team => team.teamId === teamId)
        ?.info;
    };

    const getTeamsFromRole = (role: TeamRole): Team[] => {
      return getAccountPermission(
        store.userProfile(),
        store.selectedAccount()?.accountId
      )
        ?.teamPermissions.filter(permission => permission.roles.includes(role))
        .map(team => ({
          teamId: team.teamId,
          info: getTeamInfo(team.teamId)
        }));
    };

    const hasAnyAccountRole = (accountRoles: AccountRole[]): boolean => {
      return accountRoles.some(role =>
        getAccountRoles(
          store.userProfile(),
          store.selectedAccount().accountId
        )?.includes(role)
      );
    };

    const setHistoryBackButton = (showHistoryBackButton: boolean): void => {
      patchState(store, { showHistoryBackButton });
    };

    const setPageTitle = (
      title = store.pageTitle(),
      subTitle = store.pageSubTitle()
    ): void => {
      patchState(store, { pageTitle: title, pageSubTitle: subTitle });
    };

    return {
      // User & Account
      setUserProfile,
      setAccount,
      getUniqueAccountId,

      // Authorization
      getUserAccountPermission,
      getAllAccountTeams,
      getTeamInfo,
      getTeamsFromRole,
      hasAnyAccountRole,

      // Application
      setHistoryBackButton,
      setPageTitle
    };
  }),

  withComputed((store, responsive = inject(BreakpointObserver)) => {
    const profile = store.userProfile;
    const account = store.selectedAccount;
    const adminRoles: AccountRole[] = ['admin', 'owner'];

    const language = computed(() => profile()?.info?.language);

    const userFirstName = computed(() => profile()?.info?.firstName);

    const userInitials = computed(() =>
      getNameInitials(profile()?.info?.firstName, profile()?.info?.lastName)
    );

    const isSelectedAccountUnconfirmed = computed(
      () => !!store.selectedAccount()?.unconfirmedCriticalInfo
    );

    const isLoggedIn = computed(() => !!profile()?.userId);

    const isSuperAdmin = computed(() => profile()?.userRole === 'superadmin');

    const isAdmin = computed(
      () =>
        isSuperAdmin() ||
        hasAnyAccountRole(profile(), account()?.accountId, adminRoles)
    );

    const isRidePlannerWithoutTeam = computed(
      () =>
        isSuperAdmin() ||
        hasAnyAccountRole(profile(), account()?.accountId, [
          ...adminRoles,
          'rideplanner'
        ])
    );

    const isRidePlanner = computed(
      () =>
        isRidePlannerWithoutTeam() ||
        hasAnyTeamRole(profile(), account()?.accountId, ['rideplanner'])
    );

    const isDrivePlannerWithoutTeam = computed(
      () =>
        isSuperAdmin() ||
        hasAnyAccountRole(profile(), account()?.accountId, [
          ...adminRoles,
          'driveplanner'
        ])
    );

    const isDrivePlanner = computed(
      () =>
        isDrivePlannerWithoutTeam() ||
        hasAnyTeamRole(profile(), account()?.accountId, ['driveplanner'])
    );

    const hasRidePlanningService = computed(
      () =>
        !!account()?.subscriptions.find(
          service =>
            service.info.service === 'rideplanning' && service.revoked === false
        )
    );

    const hasDrivePlanningService = computed(
      () =>
        !!account()?.subscriptions.find(
          service =>
            service.info.service === 'driveplanning' &&
            service.revoked === false
        )
    );

    const hasCeliosIntegrationService = computed(
      () =>
        !!account()?.subscriptions.find(
          service =>
            service.info.service === 'celios_integration' &&
            service.revoked === false
        )
    );

    const hasDispoliveIntegrationService = computed(
      () =>
        !!account()?.subscriptions.find(
          service =>
            service.info.service === 'dispolive_integration' &&
            service.revoked === false
        )
    );

    const hasOnlyOneAccount = computed(
      () => profile()?.accountPermissions?.length === 1 && !isSuperAdmin()
    );

    const xsWidth = '(max-width: 599.98px)';
    const portrait = '(orientation: portrait)';
    const isMobile = toSignal(
      responsive
        .observe([xsWidth, portrait])
        .pipe(
          map(
            state => state.breakpoints[xsWidth] && state.breakpoints[portrait]
          )
        ),
      { initialValue: false }
    );

    const smWidth = '(max-width: 959.98px)';
    const landscape = '(orientation: landscape)';
    const isMobileLandscape = toSignal(
      responsive
        .observe([smWidth, landscape])
        .pipe(
          map(
            state => state.breakpoints[xsWidth] && state.breakpoints[portrait]
          )
        ),
      { initialValue: false }
    );

    return {
      // Application
      language,
      userFirstName,
      userInitials,
      isSelectedAccountUnconfirmed,
      isMobile,
      isMobileLandscape,

      // Authentication
      isLoggedIn,

      // Authorization
      isSuperAdmin,
      isAdmin,
      isRidePlannerWithoutTeam,
      isRidePlanner,
      isDrivePlannerWithoutTeam,
      isDrivePlanner,
      hasRidePlanningService,
      hasDrivePlanningService,
      hasCeliosIntegrationService,
      hasDispoliveIntegrationService,
      hasOnlyOneAccount
    };
  })
);
