import {
  AccountPermission,
  AccountRole,
  TeamPermission,
  TeamRole,
  UserProfile
} from '@moxi.gmbh/moxi-typescriptmodels';

/**
 * Retrieves the account permission for a specific account ID from the user profile.
 *
 * @param userProfile - The user profile containing the account permissions.
 * @param accountId - The ID of the account.
 * @returns The account permission for the specified account ID.
 */
export const getAccountPermission = (
  userProfile: UserProfile,
  accountId: string
): AccountPermission => {
  return userProfile?.accountPermissions.find(
    permissions => permissions.accountId === accountId
  );
};

/**
 * Retrieves the account roles for a specific account ID from the user profile.
 *
 * @param userProfile - The user profile containing the account roles.
 * @param accountId - The ID of the account.
 * @returns An array of account roles for the specified account ID.
 */
export const getAccountRoles = (
  userProfile: UserProfile,
  accountId: string
): AccountRole[] => {
  if (!userProfile || !accountId) {
    return;
  }

  return getAccountPermission(userProfile, accountId)?.roles;
};

/**
 * Checks if the user profile has any of the specified account roles for a specific account ID.
 *
 * @param userProfile - The user profile to check.
 * @param accountId - The ID of the account.
 * @param accountRoles - An array of account roles to check.
 * @returns True if the user profile has any of the specified account roles, false otherwise.
 */
export const hasAnyAccountRole = (
  userProfile: UserProfile,
  accountId: string,
  accountRoles: AccountRole[]
): boolean => {
  return accountRoles?.some(role =>
    getAccountRoles(userProfile, accountId)?.includes(role)
  );
};

/**
 * Retrieves the team permissions for a specific account ID from the user profile.
 *
 * @param userProfile - The user profile containing the team permissions.
 * @param accountId - The ID of the account.
 * @returns An array of team permissions for the specified account ID.
 */
export const getAccountTeamPermissions = (
  userProfile: UserProfile,
  accountId: string
): TeamPermission[] => {
  if (!userProfile || !accountId) {
    return;
  }

  return getAccountPermission(userProfile, accountId)?.teamPermissions;
};

/**
 * Checks if the user profile has any of the specified team roles for a specific account ID.
 *
 * @param userProfile - The user profile to check.
 * @param accountId - The ID of the account.
 * @param teamRoles - An array of team roles to check.
 * @returns True if the user profile has any of the specified team roles, false otherwise.
 */
export const hasAnyTeamRole = (
  userProfile: UserProfile,
  accountId: string,
  teamRoles: TeamRole[]
): boolean => {
  return teamRoles?.some(role =>
    getAccountTeamPermissions(userProfile, accountId)?.some(permission =>
      permission.roles.includes(role)
    )
  );
};
